<template>
  <v-container fluid>
    <search-form @submit="handleSearchFormSubmit"/>
    <v-card class="mt-5">
      <v-card-title>
        <v-icon
          small
          color="white"
          class="elevation-6 pink lighten-1 pa-2 mr-3"
        >
          mdi-format-list-bulleted-square
        </v-icon>
        {{ $vuetify.lang.t('$vuetify.healthReport.list') }}
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="healthReportItems"
          item-key="id"
          hide-default-footer
        >

          <template v-slot:item.reportName="{ item }">
            {{ $vuetify.lang.t(`$vuetify.serviceItem.${ snakeToCamel(item.reportType.toLowerCase()) }`) }}
          </template>

          <template
            v-slot:item.frequency="{ item }"
          >
            <span
              v-if="item.reportFrequency"
            >
            {{ $vuetify.lang.t(`$vuetify.serviceFrequency.${ snakeToCamel(item.reportFrequency.toLowerCase()) }`) }}
            </span>

            <span
              v-else
            >
              -
            </span>
          </template>

          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | moment('YYYY-MM-DD HH:mm') }}
          </template>

          <template v-slot:item.actions="{ item }">
            <a
              v-if="item.reportType.startsWith('ECG_')"
              :href="item.sourceMeta"
              target="_blank"
            >
              <v-icon
                v-if="item.reportType.startsWith('ECG_')"
                :href="item.sourceMeta"
                target="_blank"
                class="mr-2"
                color="info"
                small
              >
                mdi-eye
              </v-icon>
            </a>
            <v-icon
              v-else
              class="mr-2"
              color="info"
              small
              @click="handleShowItem(item)"
            >
              mdi-eye
            </v-icon>
          </template>

        </v-data-table>
        <v-pagination
          v-model="queryParams.params.page"
          :length="queryParams.params.pageCount"
        />
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $vuetify.lang.t('$vuetify.common.warning') }}
        </v-card-title>
        <v-card-text
          v-if="selected.length > 0"
        >
          {{ $vuetify.lang.t('$vuetify.message.deleteConfirm') }}
          <Strong>
            {{ selected[0].name }}
          </Strong>
          ?
        </v-card-text>
        <v-card-text
          v-else
        >
          {{ $vuetify.lang.t('$vuetify.message.needSelectedOne') }}
        </v-card-text>
        <v-card-actions
          v-if="selected.length > 0"
        >
          <v-spacer></v-spacer>
          <v-btn
            color="info darken-1"
            @click="deleteDialog = false"
          >
            {{ $vuetify.lang.t('$vuetify.common.cancel') }}
          </v-btn>
          <v-btn
            color="error darken-1"
            @click="handleDeleteDialog"
          >
            {{ $vuetify.lang.t('$vuetify.common.delete') }}
          </v-btn>
        </v-card-actions>
        <v-card-actions
          v-else
        >
          <v-spacer></v-spacer>
          <v-btn
            color="info darken-1"
            @click="deleteDialog = false"
          >
            {{ $vuetify.lang.t('$vuetify.common.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  import { snakeToCamel } from '@/utils';

  export default {
    components: {
      SearchForm: () => import('./SearchForm')
    },
    props: {
      patientId: {
        type: String,
        default: () => null
      }
    },
    data () {
      return {
        selected: [],
        healthReportItems: [],
        editItem: null,
        deleteDialog: false,
        queryParams: {
          params: {
            page: 1,
            sort: ['createdAt,desc'],
            pageCount: 1
          }
        }
      }
    },

    computed: {

      ...mapGetters({
        healthReports: 'healthReport/healthReports'
      }),

      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.healthReport.reportName'),
            align: 'start',
            sortable: false,
            value: 'reportName',
          },{
            text: this.$vuetify.lang.t('$vuetify.healthReport.startDate'),
            align: 'start',
            sortable: false,
            value: 'startDate',
          },{
            text: this.$vuetify.lang.t('$vuetify.healthReport.endDate'),
            align: 'start',
            sortable: false,
            value: 'endDate',
          },{
            text: this.$vuetify.lang.t('$vuetify.healthReport.createdAt'),
            align: 'start',
            sortable: false,
            value: 'createdAt',
          },{
            text: this.$vuetify.lang.t('$vuetify.healthReport.frequency'),
            align: 'start',
            sortable: false,
            value: 'frequency',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.actions'),
            align: 'start',
            sortable: false,
            value: 'actions',
          }
        ]
      }
    },

    watch: {
      'queryParams.params.page': {
         handler: function(newValue) {
            let params = Object.assign({}, this.queryParams.params)
            params.page = newValue - 1
            this.healthReportQuery({params: params, data: this.queryParams.data }).then( (res) => {
              this.queryParams.params.pageCount = res.totalPages
              this.healthReportItems = this.healthReports
            })
         }
      }
    },

    created () {
      if (this.queryParams.data) {
        this.queryParams.data.patientId = this.patientId
      } else {
        this.queryParams.data = { patientId: this.patientId }
      }
      let params = Object.assign({}, this.queryParams.params)
      params.page--
      this.healthReportQuery({
        params: params,
        data: this.queryParams.data
      }).then( (res) => {
        this.queryParams.params.pageCount = res.totalPages
        this.healthReportItems = this.healthReports
      })

    },

    methods: {

      ...mapActions({
        healthReportQuery: 'healthReport/query'
      }),

      snakeToCamel,

      handleSearchFormSubmit(data) {
        this.queryParams.data = Object.assign( { patientId: this.patientId }, data)
        let params = Object.assign({}, this.queryParams.params)
        params.page = 0
        this.healthReportQuery({params: params, data: this.queryParams.data }).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
          this.healthReportItems = this.healthReports
        })
      },

      handleShowItem (item) {
        this.$router.push({ name: 'healthReportShow', params: { id: item.id + '' }})
      }
    }
  }
</script>
