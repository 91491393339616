var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('search-form',{on:{"submit":_vm.handleSearchFormSubmit}}),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_c('v-icon',{staticClass:"elevation-6 pink lighten-1 pa-2 mr-3",attrs:{"small":"","color":"white"}},[_vm._v(" mdi-format-list-bulleted-square ")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.healthReport.list'))+" "),_c('v-spacer')],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.healthReportItems,"item-key":"id","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.reportName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(("$vuetify.serviceItem." + (_vm.snakeToCamel(item.reportType.toLowerCase())))))+" ")]}},{key:"item.frequency",fn:function(ref){
var item = ref.item;
return [(item.reportFrequency)?_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(("$vuetify.serviceFrequency." + (_vm.snakeToCamel(item.reportFrequency.toLowerCase())))))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'YYYY-MM-DD HH:mm'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.reportType.startsWith('ECG_'))?_c('a',{attrs:{"href":item.sourceMeta,"target":"_blank"}},[(item.reportType.startsWith('ECG_'))?_c('v-icon',{staticClass:"mr-2",attrs:{"href":item.sourceMeta,"target":"_blank","color":"info","small":""}},[_vm._v(" mdi-eye ")]):_vm._e()],1):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.handleShowItem(item)}}},[_vm._v(" mdi-eye ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-pagination',{attrs:{"length":_vm.queryParams.params.pageCount},model:{value:(_vm.queryParams.params.page),callback:function ($$v) {_vm.$set(_vm.queryParams.params, "page", $$v)},expression:"queryParams.params.page"}})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.common.warning'))+" ")]),(_vm.selected.length > 0)?_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.message.deleteConfirm'))+" "),_c('Strong',[_vm._v(" "+_vm._s(_vm.selected[0].name)+" ")]),_vm._v(" ? ")],1):_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.message.needSelectedOne'))+" ")]),(_vm.selected.length > 0)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info darken-1"},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.common.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"error darken-1"},on:{"click":_vm.handleDeleteDialog}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.common.delete'))+" ")])],1):_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info darken-1"},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.common.ok'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }